import { Modification, OnlineProduct } from "Types";
import { BundleSlideDirection, ProgressStep } from "..";

export enum PROGRESS_STEP_ACTION {
    INIT_STEPS = "INIT_STEPS",
    UPDATE_STEP = "UPDATE_STEP",
    MOVE_STEP = "MOVE_STEP",
    CLICK_STEP = "CLICK_STEP",
    MODIFY_STEP = "MODIFY_STEP",
    SELECT_ITEM = "SELECT_ITEM",
    SELECT_MODS = "SELECT_MODS",
    VIEW_EDIT_MODS = "VIEW_EDIT_MODS",
    SLIDE_DIRECTION = "SLIDE_DIRECTION",
    STOP_MODIFYING = "STOP_MODIFYING"
}

type ProgressStepAction =
    | { type: PROGRESS_STEP_ACTION.INIT_STEPS; payload: ProgressStep[] }
    | { type: PROGRESS_STEP_ACTION.UPDATE_STEP; payload: { step: ProgressStep; hasBeenEdited: boolean } }
    | { type: PROGRESS_STEP_ACTION.MOVE_STEP; payload: { currentStep: ProgressStep; isForward: boolean } }
    | { type: PROGRESS_STEP_ACTION.CLICK_STEP; payload: { stepNumber: number } }
    | { type: PROGRESS_STEP_ACTION.SELECT_MODS; payload: { modifications: Modification } }
    | {
          type: PROGRESS_STEP_ACTION.MODIFY_STEP;
          payload: { step: number; bundleCategoryId: string; isModifyMode: boolean };
      }
    | {
          type: PROGRESS_STEP_ACTION.SELECT_ITEM;
          payload: { selectedBundleItem: OnlineProduct | null, modifications?: Modification | null };
      }
    | {
          type: PROGRESS_STEP_ACTION.SLIDE_DIRECTION;
          payload: { contentSlideDirection: BundleSlideDirection; isModifyMode: boolean; hasBeenEdited: boolean };
      }
    | {
          type: PROGRESS_STEP_ACTION.STOP_MODIFYING;
    } | {
        type: PROGRESS_STEP_ACTION.VIEW_EDIT_MODS;
        payload: { viewEditMods: boolean };
      };

export type ProgressStepState = {
    steps: ProgressStep[];
    isModifyMode: boolean;
    hasBeenEdited: boolean;
    contentSlideDirection: BundleSlideDirection;
    currentProgressStep?: ProgressStep | null;
    selectedBundleItem: OnlineProduct | null;
    modifications?: Modification | null;
    numberOfSteps: number;
    skipSteps?: number[] | null;
    viewEditMods: boolean;
};

export const initialState: ProgressStepState = {
    steps: [],
    contentSlideDirection: "right",
    hasBeenEdited: false,
    isModifyMode: false,
    viewEditMods: true,
    currentProgressStep: null,
    selectedBundleItem: null,
    modifications: null,
    skipSteps: null,
    numberOfSteps: 0
};

const getCurrentProgressStep = (val: ProgressStep) => val.isCurrentProgressStep;

const getSkippedSteps = (steps: ProgressStep[]) => steps.filter(val => val.skipStep).map(val => val.progressStep);

/** This takes the current step and checks if previous ones are skipped  */
export const allPreviousStepsAreSkipped = (steps: number[], currentStep: number) => {
    return Array.from({ length: currentStep - 1 }, (_, i) => i + 1).every(step => steps.includes(step));
};

/**
 * [FUNCTION] - Get Next Available step that is after or before current step that doesn't have skip step
 * @param steps
 * @param currentStep
 * @param isForward
 * @returns
 */
export const nextAvailableStep = (steps: ProgressStep[], currentStep: number, isForward: boolean) => {
    return isForward
        ? steps.find(val => val.progressStep > currentStep && !val.skipStep)
        : [...steps].reverse().find(val => val.progressStep < currentStep && !val.skipStep);
};

/** As this setting of state was a bit more complicated - uses a reducer instead */
export const setBundleCreatorSteps = (state: ProgressStepState, action: ProgressStepAction): ProgressStepState => {
    switch (action.type) {
        /** Initial state of steps + creator */
        case PROGRESS_STEP_ACTION.INIT_STEPS:
            return {
                ...state,
                steps: action.payload,
                currentProgressStep: action.payload.find(getCurrentProgressStep),
                selectedBundleItem: null,
                skipSteps: getSkippedSteps(action.payload),
                numberOfSteps: action.payload.length
            };
        /** Update current selected step - used when modifying from summary */
        case PROGRESS_STEP_ACTION.UPDATE_STEP: {
            const { step, hasBeenEdited } = action.payload;
            const updatedSteps = state.steps.map(currentStep =>
                currentStep.progressStep === step.progressStep ? step : currentStep
            );
            return {
                ...state,
                steps: updatedSteps,
                currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                selectedBundleItem: null,
                modifications: null,
                isModifyMode: false,
                contentSlideDirection: "left",
                hasBeenEdited
            };
        }
        /** Move back or forwards */
        case PROGRESS_STEP_ACTION.MOVE_STEP: {
            const { currentStep, isForward } = action.payload;
            const completedStep = {
                ...currentStep,
                progressStepCompleted: !!currentStep.selectedItemId,
                isCurrentProgressStep: false,
                isDisabled: !currentStep.selectedItemId
            };
            const nextProgressStep = isForward ? currentStep.progressStep + 1 : currentStep.progressStep - 1;

            const hasStepsToSkip = (state.skipSteps?.length || 0) > 0;
            const nextStep = !hasStepsToSkip
                ? state.steps.find(step => step.progressStep === nextProgressStep)
                : nextAvailableStep(state.steps, currentStep.progressStep, isForward);

            if (nextStep) {
                const updatedNextStep = {
                    ...nextStep,
                    isCurrentProgressStep: true,
                    progressStepCompleted: !!nextStep.selectedItemId
                };

                const updatedSteps = state.steps.map(step =>
                    step.progressStep === completedStep.progressStep
                        ? completedStep
                        : step.progressStep === updatedNextStep.progressStep
                          ? updatedNextStep
                          : step
                );

                return {
                    ...state,
                    steps: updatedSteps,
                    currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                    selectedBundleItem: null,
                    modifications: null,
                    viewEditMods: !isForward,
                    contentSlideDirection: isForward ? "right" : "left"
                };
            }

            const updatedSteps = state.steps.map(step =>
                step.progressStep === completedStep.progressStep ? completedStep : step
            );

            return {
                ...state,
                steps: updatedSteps,
                currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                selectedBundleItem: null,
                modifications: null,
                viewEditMods: !isForward,
                contentSlideDirection: isForward ? "right" : "left"
            };
        }
        /** On click of steps - go to step that isn't disabled */
        case PROGRESS_STEP_ACTION.CLICK_STEP: {
            const { stepNumber } = action.payload;
            const nextStep = state.steps.find(step => step.progressStep === stepNumber);
            const currentStep = state.steps.find(step => step.isCurrentProgressStep);

            if (nextStep && currentStep) {
                const updatedNextStep = {
                    ...nextStep,
                    isCurrentProgressStep: true,
                    progressStepCompleted: false
                };

                const updatedCurrentStep = {
                    ...currentStep,
                    isCurrentProgressStep: false,
                    progressStepCompleted: !!currentStep.selectedItemId
                };

                const updatedSteps = state.steps.map(step =>
                    step.progressStep === updatedNextStep.progressStep
                        ? updatedNextStep
                        : step.progressStep === updatedCurrentStep.progressStep
                          ? updatedCurrentStep
                          : step
                );

                return {
                    ...state,
                    steps: updatedSteps,
                    currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                    selectedBundleItem: null,
                    modifications: null,
                    viewEditMods: nextStep.progressStep < currentStep.progressStep,
                    contentSlideDirection: nextStep.progressStep < currentStep.progressStep ? "left" : "right"
                };
            } else if (nextStep) {
                /** Used when going backwards from the bundle addon section */
                const updatedNextStep = {
                    ...nextStep,
                    isCurrentProgressStep: true,
                    progressStepCompleted: false
                };

                const updatedSteps = state.steps.map(step =>
                    step.progressStep === updatedNextStep.progressStep ? updatedNextStep : step
                );

                return {
                    ...state,
                    steps: updatedSteps,
                    currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                    selectedBundleItem: null,
                    modifications: null,
                    viewEditMods: true,
                    contentSlideDirection: "left"
                };
            }

            return state;
        }
        /** Set step to modify as the current selection - used when clicking on summary */
        case PROGRESS_STEP_ACTION.MODIFY_STEP: {
            const { step, bundleCategoryId, isModifyMode } = action.payload;

            const stepToModify = state.steps.find(
                val => val.progressStep === step && val.progressId === bundleCategoryId
            );

            if (stepToModify) {
                const updatedStep = {
                    ...stepToModify,
                    isCurrentProgressStep: true,
                    progressStepCompleted: false
                };

                const updatedSteps = state.steps.map(step =>
                    step.progressStep === updatedStep.progressStep
                        ? updatedStep
                        : { ...step, isDisabled: true, isCurrentProgressStep: false }
                );

                return {
                    ...state,
                    steps: updatedSteps,
                    isModifyMode: isModifyMode,
                    currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                    selectedBundleItem: null,
                    modifications: null,
                    viewEditMods: true,
                    contentSlideDirection: "left"
                };
            }

            return state;
        }
        case PROGRESS_STEP_ACTION.STOP_MODIFYING: {
            const { steps } = state;
            const updatedSteps = steps.map(step => ({
                ...step,
                isCurrentProgressStep: false,
                progressStepCompleted: true
            }));
            return {
                ...state,
                isModifyMode: false,
                viewEditMods: false,
                selectedBundleItem: null,
                modifications: null,
                steps: updatedSteps,
                currentProgressStep: updatedSteps.find(getCurrentProgressStep),
                contentSlideDirection: "right"
            };
        }
        /** Keep selection of item in creator */
        case PROGRESS_STEP_ACTION.SELECT_ITEM: {
            const { selectedBundleItem, modifications } = action.payload;
            return {
                ...state,
                selectedBundleItem,
                modifications: modifications || null
            };
        }
        case PROGRESS_STEP_ACTION.SELECT_MODS: {
            const { modifications } = action.payload;
            return {
                ...state,
                modifications
            };
        }
        case PROGRESS_STEP_ACTION.SLIDE_DIRECTION: {
            const { contentSlideDirection, isModifyMode, hasBeenEdited } = action.payload;
            return {
                ...state,
                contentSlideDirection,
                isModifyMode,
                hasBeenEdited
            };
        }
        case PROGRESS_STEP_ACTION.VIEW_EDIT_MODS: {
            const { viewEditMods } = action.payload;
            return {
                ...state,
                viewEditMods
            };
        }
        default:
            return state;
    }
};
