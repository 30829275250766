import React from "react";

import { Box, Flex, Image } from "Atoms";
import { useTheme } from "ThemeProvider";
import { TruncatedText } from "../../shared/TruncatedText";

type ProductImageProps = {
    productImage: string;
    isOutOfStock: boolean;
    productName: string;
    isPortrait: boolean;
};

export const ProductImage: React.FC<ProductImageProps> = ({ productImage, isOutOfStock, productName, isPortrait }) => {
    const { colors } = useTheme();

    return (
        <>
            {productImage ? (
                <Image
                    src={productImage}
                    h={isPortrait ? "300px" : "260px"}
                    w="100%"
                    borderRadius={"17px 17px 0 0"}
                    filter={isOutOfStock ? "grayscale(100%)" : "none"}
                    opacity={isOutOfStock ? 0.8 : 1}
                    objectFit={"cover"}
                />
            ) : (
                <Flex
                    h={isPortrait ? "300px" : "260px"}
                    px={4}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display={isOutOfStock ? "none" : "flex"}
                >
                    <TruncatedText
                        fontWeight={"600"}
                        fontSize={isPortrait ? "4xl" : "3xl"}
                        color={colors.expressPrimary}
                        lines={4}
                        textAlign={"center"}
                    >
                        {productName}
                    </TruncatedText>
                </Flex>
            )}
        </>
    );
};
