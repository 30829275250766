import React from "react";

import { Box, Flex } from "Atoms";
import { TruncatedText } from "../../shared/TruncatedText";

type FooterProps = {
    productName: string;
    productPrice: string;
    isOutOfStock: boolean;
    isPortrait: boolean;
};

export const ProductCardFooter: React.FC<FooterProps> = ({ productName, productPrice, isOutOfStock, isPortrait }) => {
    return (
        <Flex
            h={isPortrait ? "90px" : "69px"}
            w="100%"
            bg={"white"}
            borderRadius={"0 0 14px 14px"}
            position="absolute"
            bottom={"0"}
            left={"0"}
            justifyContent={"space-between"}
            color={isOutOfStock ? "expressDarkGrey" : "black"}
            alignItems={"center"}
            px={isPortrait ? 6 : 5}
            py={isPortrait ? 4 : 3}
        >
            <TruncatedText my="auto" fontSize={isPortrait ? "md" : "sm"} fontWeight={"500"}>
                {productName}
            </TruncatedText>
            <Box my="auto" fontSize={isPortrait ? "sm" : "xs"} minW={"50px"} textAlign={"right"}>
                {productPrice}
            </Box>
        </Flex>
    );
};
