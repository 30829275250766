import React from "react";
import { IoCard } from "@react-icons/all-files/io5/IoCard";

import { Box, Flex, Image } from "Atoms";
import { PaymentMethod } from "Constants";
import { SwishLogoPng } from "Assets";
import { Languagekey, useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { getTextColor } from "Utils";

type Props = {
    paymentMethod: PaymentMethod.CARD | PaymentMethod.SWISH;
    selected: boolean;
    onClick: (paymentMethod: PaymentMethod) => void;
};

export const ExpressPaymentButton: React.FC<Props> = ({ paymentMethod, selected, onClick }) => {
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();
    const { translate } = useLanguage();

    const paymentIcon =
        paymentMethod === PaymentMethod.CARD ? (
            <Box as={IoCard} size={"240px"} color={colors.expressPrimary} />
        ) : (
            <Box p={8} size={"240px"} color={colors.expressPrimary}>
                <Image src={SwishLogoPng} alt="Swish logo" />
            </Box>
        );
    // needed for triggering the correct translation
    const paymentText = paymentMethod.toLowerCase();
    return (
        <Box
            width={isPortrait ? "389px" : "327px"}
            height={isPortrait ? "464px" : "390px"}
            color={colors.expressPrimary}
            borderColor={colors.expressPrimary}
            border="2px solid"
            rounded="1.25rem"
            onClick={() => onClick(paymentMethod)}
            backgroundColor={selected ? colors.expressSecondary : "white"}
        >
            <Flex direction="column" justifyContent="center" alignItems="center" height="100%">
                {paymentIcon}
                <Box fontSize="3xl" fontWeight="600" color={getTextColor(colors.expressSecondary)}>
                    {translate(paymentText as Languagekey)}
                </Box>
            </Flex>
        </Box>
    );
};
