import React from "react";
import styled from "styled-components";

import { Flex, Box, FlexProps, Image } from "Atoms";
import { useTheme } from "ThemeProvider";

const CardAmination = styled(Flex)`
    &.landscape {
        @keyframes xAxis {
            0% {
                transform: translateX(calc(0px));
                width: 171px;
                height: 220px;
            }
            50% {
                height: 150px;
                width: 117px;
            }
            80% {
                height: 40px;
                width: 31px;
            }
            100% {
                transform: translateX(calc(100vw - 75px));
                height: 40px;
                width: 31px;
            }
        }

        display: block;
        animation: xAxis 0.9s forwards cubic-bezier(0.73, 0.29, 0.1, 0.88);
        z-index: 10000;
        overflow: hidden;
    }

    &.portrait {
        @keyframes yAxis {
            0% {
                transform: translateY(calc(0px));
                width: 171px;
                height: 220px;
            }
            50% {
                height: 150px;
                width: 117px;
            }
            80% {
                height: 40px;
                width: 31px;
            }
            100% {
                transform: translateY(calc(100vh - 75px));
                height: 20px;
                width: 15px;
            }
        }

        display: block;
        animation: yAxis 0.9s forwards cubic-bezier(0.73, 0.29, 0.1, 0.88);
        z-index: 1000;
    }
`;

type Props = {
    imageUrl: string;
    startAnimation: boolean;
} & FlexProps;

//TODO: will need some adjustments in portriat 
export const ProductCardAnimation: React.FC<Props> = ({ imageUrl, startAnimation = false, ...props }) => {
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();

    return (
        <CardAmination
            p={1}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            width="171px"
            height="220px"
            borderRadius={"0.5rem"}
            position="absolute"
            top={0}
            right={0}
            {...props}
            opacity={startAnimation ? 1 : 0}
            className={startAnimation ? (!isPortrait ? "landscape" : "portrait") : ""}
        >
            <Flex
                h="100%"
                direction={"column"}
                borderColor={colors.expressSecondaryDark}
                border={"1px solid"}
                position="relative"
            >
                <Image src={imageUrl} h={"78%"} objectFit={"cover"} />
                <Box
                    h={"22%px"}
                    width="100%"
                    borderRadius={"0 0 0.5rem 0.5rem"}
                    backgroundColor={colors.expressPrimary}
                    textAlign="center"
                >
                    {"....."}
                </Box>
            </Flex>
        </CardAmination>
    );
};
