import React from "react";

import { Box, Flex, Text, CloseButtonProps } from "Atoms";
import { IoArrowBackCircle } from "@react-icons/all-files/io5/IoArrowBackCircle";
import { useTheme } from "ThemeProvider";

export const ExpressBackButton: React.FC<{ title?: string } & CloseButtonProps> = ({ title = "", ...props }) => {
    const { colors } = useTheme();
    return (
        <Flex alignItems={"center"} {...props}>
            <Box as={IoArrowBackCircle} w="40px" h="40px" mr={6} color={colors.expressPrimary} />
            <Text fontSize={"xl"}>{title}</Text>
        </Flex>
    );
};
