import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import { BaseBoxProps, Box } from "Atoms";

export type TruncatedTextProps = {
    lines?: number;
} & PropsWithChildren &
    BaseBoxProps;

const StyledTruncatedText = styled.div<{ lines?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lines || 2};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
`;

export const TruncatedText: React.FC<TruncatedTextProps> = ({ lines = 2, children, ...props }) => {
    return (
        <Box overflow={"hidden"} {...props}>
            <StyledTruncatedText lines={lines}>{children}</StyledTruncatedText>
        </Box>
    );
};
