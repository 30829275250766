import React from "react";
import { FaArrowAltCircleUp } from "@react-icons/all-files/fa/FaArrowAltCircleUp";
import { FaArrowAltCircleDown } from "@react-icons/all-files/fa/FaArrowAltCircleDown";

import { Flex, Header, Box, Text } from "Atoms";
import { Languagekey, useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { useOrderWindowsStore } from "Stores";
import { onlineOrderConstants } from "Constants";

type Props = {
    numberOfProducts: number;
    isCartExtended: boolean;
    handleCartExtension?: () => void;
};

export const ExpressCartHeader: React.FC<Props> = ({
    numberOfProducts,
    isCartExtended = false,
    handleCartExtension
}) => {
    const {
        colors: { expressPrimary }
    } = useTheme();
    const { translate, translateWithArgument } = useLanguage();
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const takeAwayTextId = getActiveOrderWindow()?.takeAway
        ? onlineOrderConstants.TAKE_AWAY
        : onlineOrderConstants.EAT_HERE;

    const productHeader =
        numberOfProducts === 0
            ? translate("emptyCart")
            : numberOfProducts === 1
              ? translateWithArgument("numberOfProduct", numberOfProducts)
              : translateWithArgument("numberOfProducts", numberOfProducts);

    return (
        <Flex
            alignItems={"center"}
            justifyContent="space-between"
            width="100%"
            flex="0"
            mb={10}
            pt={isCartExtended ? 16 : 0}
            mx={2}
        >
            <Header fontSize={"2xl"} m={0} p={0}>
                {translate("yourOrderExpress")}
            </Header>
            <Flex onClick={handleCartExtension} maxH={"max-content"} alignItems="center">
                <Text m={0} p={0} marginTop={1} fontSize="md">
                    {!isCartExtended ? translate("showOrder") : translate("close")}
                </Text>
                <Box
                    fontSize="3xl"
                    ml={2}
                    as={!isCartExtended ? FaArrowAltCircleUp : FaArrowAltCircleDown}
                    color={expressPrimary}
                />
            </Flex>
            <Text mr={5} fontSize="md">
                {`${productHeader} | ${translate(takeAwayTextId as Languagekey)}`}
            </Text>
        </Flex>
    );
};
