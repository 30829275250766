import React, { useState } from "react";

import { Box } from "Atoms";
import { Drawer, DrawerBackButton, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from "Organisms";
import { ExpressProductInformationCard } from "./ExpressProductInformationCard";
import { OnlineProduct } from "Types";
import { IDrawerComponentContext } from "Providers";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";

type Props = { product: OnlineProduct };

export const ExpressProductInformationDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    onCloseDrawer,
    props: { product }
}) => {
    const { translate } = useLanguage();
    const {
        orientation: { isPortrait }
    } = useTheme();
    const [openDrawer, setOpenDrawer] = useState(true);
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        onCloseDrawer();
    };

    return (
        <Drawer open={openDrawer} onClose={handleCloseDrawer} from={isPortrait ? "bottom" : "right"}>
            <DrawerOverlay />

            <DrawerContent h={isPortrait ? "70vh" : "unset"} maxW={!isPortrait ? "67.5rem" : "unset"} px={16}>
                {/* This sizes can be changed  */}
                <DrawerHeader p={0} borderBottomWidth="1px" h="136px" alignContent={"center"}>
                    <DrawerBackButton position={"unset"} title={translate("back")} />
                </DrawerHeader>
                <DrawerBody overflow="auto" p="0" py={8} alignSelf={"center"}>
                    <Box mb={4} height={"auto"}>
                        <ExpressProductInformationCard product={product} />
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
