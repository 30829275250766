import React from "react";
import styled from "styled-components";

import { Box, BaseBoxProps } from "Atoms";

export const ExpressWrapper = styled((props: BaseBoxProps) => <Box {...props} />)`
    @supports selector(::-webkit-scrollbar) {
        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: ${props => props.theme.colors.expressPrimaryLight};
            padding-right: 10px;
        }
        ::-webkit-scrollbar-track {
            background: linear-gradient(to top, #ffffff, ${props => props.theme.colors.expressMediumGrey});
            border-left: 3px solid white;
            border-right: 3px solid white;
            background-clip: content-box;
        }
    }
`;
