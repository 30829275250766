import React from "react";

import { Box, Flex, NewDivider, Text } from "Atoms";
import { CartProduct } from "Types";
import { ExpressCheckoutCartSummaryCard } from "./ExpressCheckoutCartSummaryCard";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { ExpressButton } from "../shared/ExpressButton";
import { ExpressScrollBar } from "../shared/ExpressScrollBar";
import { formatFinancialNumbers } from "PriceUtils";
import { useQoplaStore } from "Stores";

type Props = {
    cartProducts: CartProduct[];
    navigateStepForward: () => void;
};

export const ExpressCheckoutSummaryCart: React.FC<Props> = ({ cartProducts, navigateStepForward }) => {
    const {
        colors: { expressPrimary, expressMediumGrey }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const cartTotalSum = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalPrice, 0);
    const totalNetAmount = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalNetPrice, 0);
    const vatAmount = cartTotalSum - totalNetAmount;

    return (
        <Flex width="100%" height="100%" direction={"column"}>
            <Text
                whiteSpace="nowrap"
                mt="8"
                fontSize="3xl"
                fontWeight="600"
                width={"max-content"}
                flexBasis="min-content"
            >
                {translate("yourOrderExpress")}
            </Text>
            <NewDivider opacity={1} color={expressPrimary} borderWidth="2px" width="97%" flexBasis="min-content" />
            <ExpressScrollBar display="flex" flexDirection="column" mt="4" height="max-content" py="4">
                {cartProducts.map((cartProduct, idx) => {
                    return (
                        <Box key={idx}>
                            <Box width="100%" my={0} key={cartProduct.id} color="black" py="4" pr="12">
                                <ExpressCheckoutCartSummaryCard cartProduct={cartProduct} flexBasis="content" />
                            </Box>
                            <NewDivider color={expressMediumGrey} mt={2} />
                        </Box>
                    );
                })}
            </ExpressScrollBar>
            <Flex flexBasis="min-content" flexDirection="column">
                <Flex justifyContent="space-between" fontSize="2xl" fontWeight="600" mr="12" mt="8">
                    <Text>{translate("totalSum")}</Text>
                    <Text>{formatFinancialNumbers(cartTotalSum, companyLocale)}</Text>
                </Flex>
                <Flex justifyContent="space-between" fontSize="xl" mr="12">
                    <Text>{translate("vat")}</Text>
                    <Text>{formatFinancialNumbers(vatAmount, companyLocale)}</Text>
                </Flex>
            </Flex>
            <Flex flexBasis="min-content" justifyContent="flex-end" my="6">
                <ExpressButton
                    disabled={cartProducts.length === 0}
                    themeColor={expressPrimary}
                    size={"md"}
                    children={translate("goToExpressPayment")}
                    onClick={navigateStepForward}
                    mx={3}
                />
            </Flex>
        </Flex>
    );
};
