import { MutableRefObject, useRef } from "react";

type ScrollRefHookReturn = {
    containerRef: MutableRefObject<HTMLElement | undefined>;
    resetScroll: () => void;
};

type ScrollRefHookProps = () => ScrollRefHookReturn;

export const useScrollRef: ScrollRefHookProps = () => {
    const containerRef = useRef<HTMLElement>();

    const resetScroll = () => {
        if (!!containerRef.current) {
            containerRef.current.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        }
    };

    return {
        containerRef,
        resetScroll
    };
};
