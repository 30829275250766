import React from "react";
import styled from "styled-components";

import { Flex, FlexProps } from "Atoms";

// @ts-ignore
const ScrollBarWrapper = styled(Flex)`
    display: block;
    overflow-y: ${props => props.overflowY || "auto"};
    overflow-x: hidden;
    border-right: 25px solid #ffffff00;

    @supports (scrollbar-gutter: auto) {
        scrollbar-gutter: stable;
    }

    @supports (-moz-appearance: none) and (scrollbar-color: auto) {
        scrollbar-color: ${props => `${props.theme.colors.expressPrimaryLight} ${props.theme.colors.expressLightGrey}`};
        scrollbar-width: thin;
    }
`;

// @ts-ignore
const MiniScrollBarWrapper = styled(Flex)`
    display: block;
    overflow-y: ${props => props.overflowY || "auto"};
    overflow-x: hidden;
    border-right: 5px solid #ffffff00;
    border-top: 8px solid #ffffff00;
    padding-bottom: ${(props: any) => props.paddingBottom};

    &::-webkit-scrollbar-track {
        background: linear-gradient(
            to top,
            ${props => props.theme.colors.expressSecondary},
            ${props => props.theme.colors.expressSecondary}
        ) !important;
        border-left: 3px solid ${props => props.theme.colors.expressSecondary} !important;
        border-right: 3px solid ${props => props.theme.colors.expressSecondary} !important;
        background-clip: content-box;
    }

    @supports (scrollbar-gutter: auto) {
        scrollbar-gutter: stable;
    }

    @supports (-moz-appearance: none) and (scrollbar-color: auto) {
        scrollbar-color: ${props => `${props.theme.colors.expressPrimaryLight} ${props.theme.colors.expressLightGrey}`};
        scrollbar-width: thin;
    }
`;

export const ExpressScrollBar = React.forwardRef((props: FlexProps, ref) => {
    return <ScrollBarWrapper {...props} ref={ref} />;
});

/**
 * MiniScrollBar - used in product cards / should blend in 
 */
export const ExpressMiniScrollBar = React.forwardRef((props: FlexProps, ref) => {
    return <MiniScrollBarWrapper {...props} ref={ref} />;
});
