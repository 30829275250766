import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { CartProduct, CompanyLocale } from "Types";
import { Flex, FlexProps, Text, NewDivider, MotionDiv } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { ExpressButton } from "../shared/ExpressButton";
import { formatFinancialNumbers } from "PriceUtils";
import { useQoplaStore } from "Stores";
import { getCartTotalPrice } from "Utils";

type Props = {
    cartProducts: CartProduct[];
    goToCheckout: () => void;
};

type ProductDisplayProps = {
    cartProduct: CartProduct;
    companyLocale: CompanyLocale;
    dividerColour: string;
    isOnlyOneProduct: boolean;
};

const ProductWrapper = styled(Flex)`
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const BlurredOverlay = ({ ...props }: FlexProps) => <Flex position="sticky" bottom={"-1px"} zIndex={4} {...props} />;

const SingleProductDisplay = ({ cartProduct, companyLocale, dividerColour, isOnlyOneProduct }: ProductDisplayProps) => (
    <Flex maxH="60px" w="100%" direction="column" mb={4}>
        <Flex w="100%" alignItems="center" justifyContent={"space-between"} mb={2}>
            <Text m={0} p={0} fontSize="md" fontWeight={"600"}>
                {`${cartProduct.orderProduct.quantity} x ${cartProduct.orderProduct.name}`}
            </Text>
            <Text m={0} p={0} fontSize="md" fontWeight={"600"}>
                {formatFinancialNumbers(cartProduct.orderProduct.totalPrice, companyLocale)}
            </Text>
        </Flex>
        {!isOnlyOneProduct && <NewDivider color={dividerColour} />}
    </Flex>
);

export const ExpressMiniCart: React.FC<Props> = ({ cartProducts, goToCheckout }) => {
    const miniCartWrapperRef = useRef<HTMLElement>();
    const {
        colors: { expressPrimary, expressMediumGrey }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    useEffect(() => {
        if (!!miniCartWrapperRef.current) {
            miniCartWrapperRef.current.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        }
    }, [cartProducts]);

    const cartTotalPrice = getCartTotalPrice(cartProducts);
    const showTotalPrice = cartTotalPrice > 0;
    const singleProduct = cartProducts?.length === 1;


    return (
        <Flex flexDirection="row" alignItems={"flex-start"} justifyContent="space-between" width="100%" mx={3}>
            <ProductWrapper
                direction={"column"}
                w="400px"
                height={"80px"}
                overflowY="scroll"
                overflowX={"hidden"}
                ref={miniCartWrapperRef}
            >
                {cartProducts.map(value => {
                    return (
                        <SingleProductDisplay
                            key={value.id}
                            cartProduct={value}
                            companyLocale={companyLocale}
                            dividerColour={expressMediumGrey}
                            isOnlyOneProduct={singleProduct}
                        />
                    );
                })}
                <BlurredOverlay
                    minH={"40px"}
                    maxH={"40px"}
                    background={"linear-gradient(to top, white, transparent)"}
                />
            </ProductWrapper>
            <ExpressButton
                themeColor={expressPrimary}
                size={"md"}
                {...(showTotalPrice && { price: cartTotalPrice })}
                children={translate("goToCheckout")}
                onClick={goToCheckout}
                mx={3}
            />
        </Flex>
    );
};
