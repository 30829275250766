import React, { useState, useEffect } from "react";

import { AddonsHashMapValue, CartProduct } from "Types";
import { Flex, FlexProps, Box, Fade } from "Atoms";
import { useTheme } from "ThemeProvider";
import { ExpressCartHeader } from "./ExpressCartHeader";
import { ExpressMiniCart } from "./ExpressMiniCart";
import { ExpressExtendedCart } from "./ExpressExtendedCart";
import { ExpressSideCart } from "./ExpressSideCart";

/** Cart products are temp? probably access the order window store here instead */
type Props = {
    cartProducts: CartProduct[];
    goToCheckout: () => void;
    handleOpenViewToModify: (cartProduct: CartProduct, productAddons?: AddonsHashMapValue) => void;
    isEditMode?: boolean;
} & FlexProps;

const ExpressCartWrapper = ({
    isCartExtended,
    isPortrait,
    ...props
}: FlexProps & { isCartExtended: boolean; isPortrait: boolean }) => (
    <Flex
        flexDirection={"column"}
        px={isPortrait ? 10 : 0}
        py={isPortrait ? 5 : 0}
        width="100%"
        backgroundColor="white"
        bottom={0}
        zIndex={10}
        {...props}
    />
);

export const ExpressCart: React.FC<Props> = ({
    cartProducts,
    goToCheckout,
    handleOpenViewToModify,
    isEditMode,
    ...props
}) => {
    const [isCartExtended, setIsCartExtended] = useState(false);
    const {
        orientation: { isPortrait },
        colors: { expressMediumGrey }
    } = useTheme();

    const onHandleCartExtension = () => {
        setIsCartExtended(!isCartExtended);
    };

    useEffect(() => {
        if (isPortrait && isCartExtended) {
            const noProductsInCart = !cartProducts.length;
            if (noProductsInCart) {
                onHandleCartExtension();
            } else if (isEditMode) {
                onHandleCartExtension()
            }
        }
    }, [cartProducts, isEditMode, isPortrait]);

    if (isPortrait && cartProducts.length < 1) {
        return null;
    }

    const defaultExpressWrapperProps: Partial<FlexProps> = {
        maxW: isPortrait ? "1080px" : "auto",
        position: isPortrait ? "fixed" : "relative",
        borderTop: isPortrait ? "1px solid" : "none",
        borderColor: expressMediumGrey,
        ...(!isPortrait && { h: "100%", w: "480px", pt: 4 })
    };

    const productQuantity = cartProducts.reduce((acc, cartProduct) => acc + cartProduct.orderProduct.quantity, 0);

    return (
        <>
            {isCartExtended && (
                <Box
                    position="fixed"
                    left="0"
                    top="0"
                    width="100vw"
                    height="100vh"
                    backgroundColor="blackAlpha.500"
                    zIndex={1}
                />
            )}
            {/** Height to be adjusted when added to views and - just here for dev component testing */}
            <ExpressCartWrapper
                isCartExtended={isCartExtended}
                isPortrait={isPortrait}
                h={!isCartExtended ? "212px" : "90%"}
                gridArea="cart"
                {...defaultExpressWrapperProps}
                {...props}
            >
                {isPortrait ? (
                    <>
                        <ExpressCartHeader
                            handleCartExtension={onHandleCartExtension}
                            isCartExtended={isCartExtended}
                            numberOfProducts={productQuantity}
                        />
                        <Fade
                            in={!isCartExtended}
                            unmountOnExit
                            transition={{
                                enter: {
                                    duration: 0.5
                                }
                            }}
                        >
                            <ExpressMiniCart cartProducts={cartProducts} goToCheckout={goToCheckout} />
                        </Fade>
                        <Fade
                            in={isCartExtended}
                            unmountOnExit
                            transition={{
                                enter: {
                                    duration: 0.5
                                }
                            }}
                        >
                            {/** May be use the slider animation for this at a later date! */}
                            <ExpressExtendedCart
                                cartProducts={cartProducts}
                                goToCheckout={goToCheckout}
                                handleOpenViewToModify={handleOpenViewToModify}
                                isEditMode={isEditMode}
                            />
                        </Fade>
                    </>
                ) : (
                    <ExpressSideCart
                        cartProducts={cartProducts}
                        numberOfProducts={productQuantity}
                        goToCheckout={goToCheckout}
                        handleOpenViewToModify={handleOpenViewToModify}
                        isEditMode={isEditMode}
                    />
                )}
            </ExpressCartWrapper>
        </>
    );
};
