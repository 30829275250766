import React from "react";
import { IoArrowBackCircle } from "@react-icons/all-files/io5/IoArrowBackCircle";

import { Box, Flex, FlexProps, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";

type Props = {
    navigateTo: () => void;
} & FlexProps;

export const ExpressGoBackButton: React.FC<Props> = ({ navigateTo, ...rest }) => {
    const { colors } = useTheme();
    const { translate } = useLanguage();

    return (
        <Flex alignItems="center" onClick={navigateTo} cursor="pointer" {...rest}>
            <Box as={IoArrowBackCircle} w="40px" h="40px" mr="4" color={colors.expressPrimary} />
            <Text fontSize="xl">{translate("back")}</Text>
        </Flex>
    );
};
