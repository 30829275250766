import React from "react";
import { HiTrash } from "@react-icons/all-files/hi/HiTrash";

import { Box } from "Atoms";
import { ExpressButton } from "./ExpressButton";
import { useLanguage } from "Providers";
import { useTheme } from "ThemeProvider";

type Props = {
    imageUrl: string;
    cancelButton?: boolean;
    primaryColor?: string;
    onClick?: () => void;
};
export const ExpressAdvertisingHeader: React.FC<Props> = ({ imageUrl, cancelButton = false, ...props }) => {
    const { translate } = useLanguage();
    const {
        colors: { expressPrimary }
    } = useTheme();
    return (
        <Box
            w="100%"
            //w="1080px" - Image will not stretch
            h="250px"
            backgroundImage={`url("${imageUrl}")`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            p="32px"
            gridArea={"advert"}
            zIndex="999"
        >
            {cancelButton && (
                <ExpressButton
                    size={"sm"}
                    variant="outline"
                    themeColor={expressPrimary}
                    rightIcon={HiTrash}
                    children={translate("cancel")}
                    {...props}
                />
            )}
        </Box>
    );
};
