import React from "react";

import { Box, Flex } from "Atoms";
import { getTextColor } from "Utils";
import { useTheme } from "ThemeProvider";
import { getModificationPriceText } from "PriceUtils";
import { useQoplaStore } from "Stores";
import { AvailableBundleItem } from "../../../../../../customer/shared/online/components/OnlineProductModal/components";
import { SingleOption } from "./SingleOption";

type Props = {
    availableBundleItems: AvailableBundleItem[];
    isPortrait: boolean;
    selectedBundleItemId: string;
    setSelectedBundleItemId: (id: string) => void;
};

export const BundleOption: React.FC<Props> = ({
    availableBundleItems,
    isPortrait,
    selectedBundleItemId,
    setSelectedBundleItemId
}) => {
    const { colors } = useTheme();
    const { companyLocale } = useQoplaStore();

    const optionTextColor = getTextColor(colors.expressSecondary);

    return (
        <Flex width={"100%"} direction={"column"} p={isPortrait ? 8 : 6}>
            {availableBundleItems.map((bundleItem, idx) => {
                const isSelected = selectedBundleItemId === bundleItem.id;
                const priceText = getModificationPriceText(
                    bundleItem.modificationsToUse ?? bundleItem.modifications,
                    companyLocale
                );
                return (
                    <Flex key={idx} onClick={() => setSelectedBundleItemId(bundleItem.id)} h="auto" mb={3} w={"100%"}>
                        <SingleOption
                            isPortrait={isPortrait}
                            isSelected={isSelected}
                            optionTextColor={optionTextColor}
                            name={bundleItem.name}
                            priceText={priceText}
                        />
                    </Flex>
                );
            })}
        </Flex>
    );
};
