import React, { useMemo } from "react";

import { Box, Flex, PseudoBoxProps, Text } from "Atoms";
import { IoIosInformationCircleOutline } from "@react-icons/all-files/io/IoIosInformationCircleOutline";
import { useTheme } from "ThemeProvider";

type Props = {
    showInfoText?: boolean;
} & PseudoBoxProps;

export const ExpressProductInformationBanner: React.FC<Props> = ({ showInfoText = false, ...props }) => {
    const { colors } = useTheme();
    const containerStyles = useMemo(
        () => ({
            justifyContent: "space-between",
            alignItems: "center",
            width: showInfoText ? "185px" : "72px",
            height: "64px",
            border: "3px solid",
            borderColor: colors.expressPrimary,
            borderRadius: "0 40px 40px 0",
            padding: showInfoText ? "16px 15px 16px 20px" : "13px",
            overflow: "hidden",
            cursor: "pointer"
        }),
        [showInfoText]
    );

    return (
        <Flex {...containerStyles} {...props}>
            {showInfoText && (
                <Text fontSize="lg" fontWeight="600" m="0">
                    Information
                </Text>
            )}
            <Box as={IoIosInformationCircleOutline} w="39.5px" h="39.5px" color={colors.expressPrimary} />
        </Flex>
    );
};
