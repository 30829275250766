import React from "react";

import { BaseBoxProps, Flex, FlexProps, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { ExpressBackButton } from "./ExpressBackButton";
import { ExpressButton } from "./ExpressButton";

type Props = {
    title: string;
    expressButtonTitle: string;
    proceedToNextStepFromUpsell: () => void;
    goBackFromUpsell: () => void;
    repsonsiveWidth?: number | null;
    wrapperProps?: FlexProps;
    shouldShowBackButton?: boolean;
} & BaseBoxProps;

export const ExpressHeaderWithButtons: React.FC<Props> = ({
    title,
    expressButtonTitle,
    goBackFromUpsell,
    proceedToNextStepFromUpsell,
    wrapperProps,
    shouldShowBackButton = true,
    repsonsiveWidth,
    ...props
}) => {
    const { translate } = useLanguage();
    const {
        orientation: { isPortrait }
    } = useTheme();
    const HeaderTextAndButton = () => (
        <>
            <Text m={0} fontSize="3xl" fontWeight={"600"}>
                {title}
            </Text>
            <ExpressButton size="md" children={expressButtonTitle} onClick={proceedToNextStepFromUpsell} />
        </>
    );
    return (
        <Flex
            alignItems={isPortrait ? "stretch" : "center"}
            justifySelf={"center"}
            width={!isPortrait ? "80%" : "90%"}
            gridArea={"header"}
            py={6}
            maxWidth={`${repsonsiveWidth}px`}
            pr={!isPortrait ? { "3xl": 8, "4xl": 0 } : { lg: 8, xl: 4 }}
            justifyContent={"space-between"}
            cursor="pointer"
            direction={isPortrait ? "column" : "row"}
            {...wrapperProps}
        >
            {shouldShowBackButton && (
                <ExpressBackButton my={isPortrait ? 6 : 0} title={translate("back")} onClick={goBackFromUpsell} />
            )}
            {isPortrait ? (
                <Flex mt={6} alignItems={"center"} justifyContent={"space-between"}>
                    <HeaderTextAndButton />
                </Flex>
            ) : (
                <HeaderTextAndButton />
            )}
        </Flex>
    );
};
