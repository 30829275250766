import React from "react";

import { Box } from "Atoms";

export const HiddenButton: React.FC<{ index: number; updateSecretCombination: (value: string) => void }> = ({
    index,
    updateSecretCombination
}) => {
    const buttonPositions = [
        { top: "0", left: "0" },
        { top: "0", right: "0" },
        { bottom: "0", left: "0" },
        { bottom: "0", right: "0" }
    ];
    return (
        <Box
            position={"absolute"}
            h={"25px"}
            w={"25px"}
            zIndex="9999"
            opacity={0}
            {...buttonPositions[index]}
            onClick={() => updateSecretCombination(`${index}`)}
        />
    );
};
