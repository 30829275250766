import React, { useState } from "react";

import { Box, Flex, Image, List, ListItem, NewGrid, PseudoBoxProps, Text } from "Atoms";
import { OnlineProduct } from "Types";
import { useQoplaStore } from "Stores";
import { formatFinancialNumbers, getBasePriceForOnlineProduct } from "PriceUtils";
import { Accordion, AccordionCloseIcon, AccordionContent, AccordionItem, AccordionTitle } from "Organisms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";

type Props = { product: OnlineProduct; hasAddons?: boolean } & PseudoBoxProps;

export const ExpressProductInformationCard: React.FC<Props> = ({ product, hasAddons = false, ...props }) => {
    const { companyLocale } = useQoplaStore();

    const { translate } = useLanguage();
    const {
        orientation: { isPortrait }
    } = useTheme();
    const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
    const { allergens, description, refProduct, refBundleProduct } = product;
    const { name, imageUrl, contents } = refProduct ||
        refBundleProduct || { id: "", name: "", imageUrl: "", contents: "" };
    const hasAllergies = allergens && allergens.length > 0;
    const getAllergyNames = allergens?.map(allergy => allergy.name) || [];

    const isLandscapeWiThAddons = !isPortrait && hasAddons;

    const Img = imageUrl ? (
        <Image mr={isLandscapeWiThAddons ? 0 : 16} src={imageUrl} w={"240px"} h="240px" objectFit={"cover"} />
    ) : (
        <Box w={"240px"} h="240px" />
    );
    const width = isLandscapeWiThAddons ? "384px" : "952px";
    const fontSize = isLandscapeWiThAddons ? "2xl" : "3xl";

    const defaultPrice = getBasePriceForOnlineProduct(product);

    return (
        <Flex w={width} {...props}>
            {!isLandscapeWiThAddons && Img}
            <Flex flexDirection="column" w={"100%"}>
                <Flex
                    fontSize={fontSize}
                    fontWeight="600"
                    justifyContent="space-between"
                    mb={4}
                    height={"auto"}
                    flexWrap={"wrap"}
                    alignItems={"baseline"}
                >
                    <Text mb={0} mr="2">
                        {name}
                    </Text>
                    <Text fontSize="xl" mb={0} color="gray.700">
                        {formatFinancialNumbers(Number(defaultPrice), companyLocale)}
                    </Text>
                </Flex>
                {isLandscapeWiThAddons && (
                    <Flex alignItems="center" justifyContent={"center"} my={8}>
                        {Img}
                    </Flex>
                )}
                <Text whiteSpace={"pre-wrap"} fontSize="lg" mb={8}>
                    {description}
                </Text>
                <Accordion multi activeIndexes={activeIndexes} width={isLandscapeWiThAddons ? width : ""}>
                    <AccordionItem isActive={activeIndexes.includes(0)}>
                        <AccordionTitle
                            disableColorTransition
                            p={0}
                            pb={activeIndexes.includes(0) ? 0 : 4}
                            _focus={{
                                boxShadow: "none"
                            }}
                            backgroundColor="none"
                            onClick={() => {
                                setActiveIndexes(current => {
                                    if (current.includes(0)) {
                                        return [];
                                    }
                                    return [0];
                                });
                            }}
                            display="flex"
                            justifyContent="flex-start"
                        >
                            <Text mb={0} fontSize="xl" fontWeight="600">
                                {translate("allergens")}
                            </Text>
                            <AccordionCloseIcon marginLeft="auto" w="24px" h="24px" />
                        </AccordionTitle>
                        <AccordionContent>
                            {hasAllergies ? (
                                <List stretch={0} listStyle="disc" pl={4}>
                                    <NewGrid
                                        templateColumns="repeat(3, 1fr)"
                                        templateRows={`repeat(${Math.ceil(3)}, auto)`}
                                        gridAutoFlow="column"
                                    >
                                        {getAllergyNames.map((item: string, idx: number) => (
                                            <ListItem key={idx} fontSize="md">
                                                {item}
                                            </ListItem>
                                        ))}
                                    </NewGrid>
                                </List>
                            ) : (
                                <Text fontSize="md">{translate("allergiesAskPersonnel")}.</Text>
                            )}
                        </AccordionContent>
                    </AccordionItem>
                    {contents && (
                        <AccordionItem isActive={activeIndexes.includes(1)}>
                            <AccordionTitle
                                p={0}
                                pt={4}
                                pb={activeIndexes.includes(1) ? 0 : 4}
                                _focus={{
                                    boxShadow: "none"
                                }}
                                backgroundColor="none"
                                onClick={() => {
                                    setActiveIndexes(current => {
                                        if (current.includes(1)) {
                                            return [];
                                        }
                                        return [1];
                                    });
                                }}
                                display="flex"
                                justifyContent="flex-start"
                            >
                                <Text mb={0} fontSize="xl" fontWeight="600">
                                    {translate("productContents")}
                                </Text>
                                <AccordionCloseIcon marginLeft="auto" w="24px" h="24px" />
                            </AccordionTitle>
                            <AccordionContent>
                                <Text fontSize="md">{contents}</Text>
                            </AccordionContent>
                        </AccordionItem>
                    )}
                </Accordion>
            </Flex>
        </Flex>
    );
};
