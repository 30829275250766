import React from "react";

import { Box, Flex } from "Atoms";
import { useTheme } from "ThemeProvider";

type Props = {
    isPortrait: boolean;
    isSelected: boolean;
    optionTextColor: string;
    name: string;
    priceText?: string;
};

export const SingleOption: React.FC<Props> = ({ isPortrait, isSelected, optionTextColor, name, priceText }) => {
    const { colors } = useTheme();
    return (
        <>
            <Box position={"relative"} height={isPortrait ? "32px" : "28px"} width={isPortrait ? "32px" : "28px"}>
                <Box
                    border={"2px solid"}
                    borderColor={colors.expressPrimary}
                    height={isPortrait ? "32px" : "28px"}
                    width={isPortrait ? "32px" : "28px"}
                    borderRadius={"full"}
                    position={"absolute"}
                    top={0}
                    left={0}
                ></Box>
                {isSelected && (
                    <Box
                        bg={colors.expressPrimary}
                        h={isPortrait ? "18px" : "14px"}
                        w={isPortrait ? "18px" : "14px"}
                        borderRadius={"full"}
                        position={"absolute"}
                        top={"7px"}
                        left={"7px"}
                    />
                )}
            </Box>
            <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                w="100%"
                {...(!priceText && { marginTop: "auto", marginBottom: "auto" })}
                pl={4}
            >
                <Box
                    color={optionTextColor}
                    fontSize={isPortrait ? "md" : "sm"}
                    lineHeight={isPortrait ? "18px" : "16px"}
                    fontWeight={"600"}
                >
                    {name}
                </Box>
                {!!priceText && (
                    <Box color={optionTextColor} fontSize={isPortrait ? "md" : "sm"} whiteSpace={"nowrap"}>
                        {priceText}
                    </Box>
                )}
            </Flex>
        </>
    );
};
