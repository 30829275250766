import React from "react";

import { BaseBoxProps, Flex } from "Atoms";
import { useTheme } from "ThemeProvider";
import { TruncatedText, TruncatedTextProps } from "../shared/TruncatedText";

type Props = {
    name: string;
    truncatedTextProps?: TruncatedTextProps;
} & BaseBoxProps;

export const TextImageFallback: React.FC<Props> = ({ name, truncatedTextProps, ...props }) => {
    const {
        colors: { expressSecondary, expressPrimary }
    } = useTheme();

    return (
        <Flex
            h={"112px"}
            w={"102px"}
            px={4}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundColor={expressSecondary}
            {...props}
        >
            <TruncatedText
                fontWeight={"600"}
                fontSize={"0.5rem"}
                color={expressPrimary}
                lines={4}
                textAlign={"center"}
                {...truncatedTextProps}
            >
                {name}
            </TruncatedText>
        </Flex>
    );
};
