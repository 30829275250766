import React from "react";

import { Box, Flex, FlexProps, Header, NewDivider } from "Atoms";
import { AddonsHashMapValue, CartProduct } from "Types";
import { ExpressCartContentCard } from "./ExpressCartContentCard";
import { formatFinancialNumbers, getCartTotalPrice } from "Utils";
import { ExpressButton } from "../shared/ExpressButton";
import { useTheme } from "ThemeProvider";
import { usePosStore, useQoplaStore } from "Stores";
import { useLanguage } from "LanguageProvider";
import { ExpressScrollBar } from "../shared/ExpressScrollBar";

const BlurredOverlay = ({ ...props }: FlexProps) => <Flex position="sticky" bottom={"-4px"} left={0} zIndex={4} {...props} />;

type Props = {
    cartProducts: CartProduct[];
    goToCheckout: () => void;
    handleOpenViewToModify: (cartProduct: CartProduct, productAddons?: AddonsHashMapValue) => void;
    isEditMode?: boolean;
};

export const ExpressExtendedCart: React.FC<Props> = ({
    cartProducts,
    goToCheckout,
    handleOpenViewToModify,
    isEditMode
}) => {
    const {
        colors: { expressPrimary, expressMediumGrey }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();
    const { addons } = usePosStore();

    const cartTotalPrice = getCartTotalPrice(cartProducts);

    return (
        <Flex width={"100%"} height="100%" direction={"column"}>
            <ExpressScrollBar
                direction={"column"}
                width="100%"
                minHeight="1380px"
                maxHeight={"1380px"}
                borderRight={"10px solid white"}
            >
                {cartProducts.map(cartProduct => {
                    const productToDisplay =
                        cartProduct.menuProduct?.refProduct || cartProduct.menuBundleProduct?.refBundleProduct;
                    const productAddons = productToDisplay?.id ? addons[productToDisplay.id] : [];
                    return (
                        <Box width={"100%"} my={6} pr={6} key={cartProduct.id}>
                            <ExpressCartContentCard
                                cartProduct={cartProduct}
                                productAddons={productAddons}
                                handleOpenViewToModify={handleOpenViewToModify}
                                isEditMode={isEditMode}
                                displayQuantitySelector
                            />
                            <NewDivider color={expressMediumGrey} my={4} />
                        </Box>
                    );
                })}
                <BlurredOverlay
                    width={"100vw"}
                    minH={"150px"}
                    maxH={"150px"}
                    background={"linear-gradient(to top, white, transparent)"}
                />
            </ExpressScrollBar>
            <Flex
                mt={6}
                flexGrow={"1"}
                position="sticky"
                bottom={"10px"}
                marginTop="auto"
                direction="column"
                height={"auto"}
                borderTop={"1px solid"}
                borderTopColor={expressMediumGrey}
            >
                <Flex mb={10} justifyContent="space-between" alignItems={"baseline"} height={"auto"}>
                    <Header ml={2} as="h2" fontSize={"2xl"}>
                        {translate("totalSum")}
                    </Header>
                    <Header mr={3} as="h2" fontSize={"2xl"}>
                        {formatFinancialNumbers(cartTotalPrice, companyLocale)}
                    </Header>
                </Flex>
                <ExpressButton
                    themeColor={expressPrimary}
                    size={"md"}
                    children={translate("goToCheckout")}
                    onClick={goToCheckout}
                    mx={3}
                    mb={4}
                    marginLeft="auto"
                />
            </Flex>
        </Flex>
    );
};
