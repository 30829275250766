import React from "react";
import { FaCommentAlt } from "@react-icons/all-files/fa/FaCommentAlt";

import { Flex, PseudoBox, Box, FlexProps } from "Atoms";
import { useModal, modals } from "Providers";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { modalNames } from "Constants";
import { getTextColor } from "Utils";

type Props = {
    comment: string;
    addComment: (comment: string) => void;
} & FlexProps;

export const ExpressCommentBox: React.FC<Props> = ({ comment, addComment, ...props }) => {
    const { openModal, closeModal } = useModal();
    const { translate } = useLanguage();
    const { colors } = useTheme();

    const addCommentAndCloseModal = (comment: string) => {
        addComment(comment);
        closeModal(modals.expressCommentModal);
    };

    return (
        <Flex
            w={"1016px"}
            h={"94px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            onClick={() =>
                openModal(modalNames.EXPRESS_COMMENT_MODAL, {
                    comment,
                    addCommentAndCloseModal,
                    closeModal
                })
            }
            {...props}
        >
            <PseudoBox
                as="button"
                type="button"
                backgroundColor={colors.expressPrimary}
                h={"64px"}
                w={"64px"}
                borderRadius={"100%"}
                border={"none"}
            >
                <Box
                    as={FaCommentAlt}
                    size={"30px"}
                    color={getTextColor(colors.expressPrimary)}
                    verticalAlign={"text-top"}
                />
            </PseudoBox>
            <Flex
                bg={colors.expressSecondary}
                color={getTextColor(colors.expressSecondary)}
                alignItems={"center"}
                borderRadius={"1.25rem"}
                px={10}
                fontFamily={"Poppins"}
                fontSize={"xl"}
                w={"928px"}
            >
                <Box>{comment || translate("expressCommentPlaceholder")}</Box>
            </Flex>
        </Flex>
    );
};
